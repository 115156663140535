import React, { useState, useContext } from "react";
import Swal from "sweetalert2";
import { AuthContext } from "../Contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export default function ResetPassword() {
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate("/login");
  };

  const [email, setEmail] = useState("");
  const context = useContext(AuthContext);
  const { serverAddress } = context;
  const resetPassword = (e) => {
    e.preventDefault();
    let requestData = { email: email };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    };
    fetch(serverAddress + "/resetPasswordRequest", requestOptions)
      .then((response) => {
        if (response.status == 404) {
          throw "User not found";
        }
        Swal.fire({
          title: "Email Sent!",
          text: "Please follow the instructions sent to your inbox.",
          icon: "success",
          confirmButtonText: "Ok, Thanks!",
        });
        setEmail("");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Sorry!",
          text: "Email not found.",
          icon: "error",
          confirmButtonText: "Try Again!",
        });
      });
  };
  return (
    <div className="FastEmail_Background">
      <div className="space-between-blocks">
        <div className="container col-xxl-10 px-xxl-5">
          {/* <!-- HEADER --> */}
          <div className="FastEmail__logo-container">
            <a href="#" onClick={goToLogin}>
              <img
                className="FastEmail__logo"
                src="/images/SwiftReply.svg"
                alt="SwiftReply logo"
              />
            </a>
          </div>

          {/* <!-- Password reset FORM --> */}
          <div id="Login" className="FastEmail__Login row px-2 justify-content-center">
            <div className="col-lg-5 mt-5 mt-lg-0" style="color; redd">
              <form className="FastEmail-auth-form" action="#!" onSubmit={resetPassword}>
                <h1 className="FastEmail-auth-form__title">Reset Password!</h1>
                <input
                  id="email"
                  className="FastEmail-auth-form__input"
                  type="email"
                  placeholder="Enter your email address..."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button className="btn btn-form w-100 mt-3">RESET</button>
              </form>
            </div>
          </div>

          <p className="FastEmail-auth-form__p mt-4 mb-0">
          <a id="Login-btn" className="FastEmail__link" href="#" onClick={goToLogin}>
          {" "}
          Back to Sign In
          </a>
          </p>

        </div>
      </div>
    </div>
  );
}
