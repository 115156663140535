import React, { useState, useContext } from "react";

import Swal from "sweetalert2";

import "./loginForm.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Contexts/AuthContext";

const LoginForm = () => {
  const {
    serverAddress,
    setToken,
    setLoggedIn,
    getAllTemplates,
    getAllTeams,
    getSubscription,
    getUserSettings,
  } = useContext(AuthContext);
  const navigate = useNavigate();

  const goToRegister = () => {
    navigate("/register");
  };

  const resetPassword = () => {
    navigate("/resetPasswordRequest");
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  const login = (e) => {
    e.preventDefault();
    setIsPending(true);

    let requestData = { email: email, password: password };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    };
    fetch(serverAddress + "/login", requestOptions)
      .then((response) => response.json())
      .then(async (responseData) => {
        //save the token in localstorage
        localStorage.setItem("fastEmailToken", responseData.accessToken);
        setError(null);
        setIsPending(false);
        setToken(responseData.accessToken);
        await getAllTeams();
        await getSubscription();
        await getUserSettings();
        await getAllTemplates(responseData);
        setLoggedIn(true);
        const event = new CustomEvent("fastEmailLogin", {
          detail: { token: responseData.accessToken },
        });
        document.dispatchEvent(event);
        navigate("/menu");
        // window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Sorry!",
          text: "Incorrect credentials",
          icon: "error",
          confirmButtonText: "Ok, Thanks!",
        });
        setIsPending(false);
        setError(err.message);
      });
  };

  return (
    <div className="FastEmail_Background">
      <div className="space-between-blocks">
        <div className="container col-xxl-10 px-xxl-5">
          {/* <!-- HEADER --> */}
          <div className="FastEmail__logo-container">
            <a href="/">
              <img
                className="FastEmail__logo"
                src={"/images/SwiftReply.svg"}
                alt="FastEmail logo"
              />
            </a>
          </div>

          {/* <!-- LOGIN FORM --> */}
          <div id="Login" className="FastEmail__Login row px-2 justify-content-center">
            <div className="col-lg-5 mt-5 mt-lg-0">
              <form className="FastEmail-auth-form" action="#!" onSubmit={login}>
                <h1 className="FastEmail-auth-form__title">Welcome Back! 👋</h1>
                <input
                  id="email"
                  className="FastEmail-auth-form__input"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <input
                  id="brand_name"
                  className="FastEmail-auth-form__input"
                  type="password"
                  minLength="6"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button className="btn btn-form w-100 mt-3">SIGN IN</button>
                <p className="FastEmail-auth-form__p mt-4 mb-0">
                  Not a member yet?
                  <a id="Signup-btn" className="FastEmail__link" href="#" onClick={goToRegister}>
                    {" "}
                    Join now
                  </a>
                </p>

                <p className="FastEmail-auth-form__p mt-4 mb-0">
                  <a id="Signup-btn" className="FastEmail__link" href="#" onClick={resetPassword}>
                    Reset Password
                  </a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;