import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  //SUBSCRIPTION DATA
  const [maxTemplates, setMaxTemplates] = useState(0);
  const [maxTeams, setMaxTeams] = useState(0);
  const [currentTeams, setCurrentTeams] = useState(0);
  const [currentTemplates, setCurrentTemplates] = useState(0);
  const [subscriptionType, setSubscriptionType] = useState("");
  const [subscription_id, setSubscriptionId] = useState("");

  const [isActive, setIsActive] = useState(true);
  const [timeToDisplay, setTimeToDisplay] = useState(new Date());

  //END SUBSCRIPTION DATA

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [token, setToken] = useState("");
  const serverAddress = process.env.REACT_APP_API;

  const [pending, setPending] = useState(true);

  const [templates, setTemplates] = useState([]);
  const [templatesLoaded, setTemplatesLoaded] = useState(false);
  const [teams, setTeams] = useState([]);

  const [tags, setTags] = useState([]);

  const [limitExceeded, setLimitExceeded] = useState(false);

  const [userSettings, setUserSettings] = useState({});

  const [selectedTeam, setselectedTeam] = useState(-1);

  useEffect(async () => {
    //reload token from last session
    if (localStorage.getItem("fastEmailToken")) {
      setToken(localStorage.getItem("fastEmailToken"));
      setLoggedIn(true);
      try {
        console.log("OK");
        await getSubscription();
        await getAllTeams();
        await getUserSettings();
      } catch (exception) {
        console.log(exception);
        localStorage.removeItem("fastEmailToken");
        alert(
          "Something went wrong and we cannot identify you!",
          " Please reload the page!"
        );
        setPending(false);
        return;
      }
      setPending(false);
    } else {
      setPending(false);
    }
  }, []);

  useEffect(() => {
    if (selectedTeam < 0) return;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    fetch(serverAddress + "/teamActive/" + selectedTeam, requestOptions)
      .then((responseData) => {
        return responseData.json();
      })
      .then((data) => {
        setIsActive(data.isValid);
      })
      .catch((err) => {
        console.log(err);
      });

    getAllTemplates();
  }, [selectedTeam]);

  useEffect(() => {
    getAllTags();
    setPending(false);
  }, [templates]);

  const getSubscription = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("fastEmailToken"),
      },
    };
    const response = await fetch(
      serverAddress + "/getSubscription",
      requestOptions
    );
    const responseData = await response.json();

    setSubscriptionType(responseData.subscription_type);
    setMaxTemplates(responseData.max_templates);
    setMaxTeams(responseData.max_teams);
    setCurrentTeams(responseData.current_teams);
    setCurrentTemplates(responseData.current_templates);
    setSubscriptionId(responseData.subscription_id);

    let date;
    if (
      responseData.subscription_ended_at === null ||
      responseData.subscription_ended_at === undefined
    ) {
      date = new Date(Date.parse(responseData.startingTime));
    } else {
      date = new Date(Date.parse(responseData.subscription_ended_at));
    }

    if (responseData.reccurence == "yearly")
      date = new Date(date.setFullYear(date.getFullYear() + 1));
    else date = new Date(date.setMonth(date.getMonth() + 1));
    setTimeToDisplay(date);
  };

  const getAllTags = () => {
    let newTags = [];
    for (let template of templates) {
      if (template.teamId === selectedTeam) {
        template.tags = template.tags.split(",").map((el) => el.trim());
        let currentTags = template.tags;
        newTags = [...new Set([...currentTags, ...newTags])];
      }
    }
    setTags(newTags);
  };

  const getUserSettings = async () => {
    console.log("THIS REQUEST");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("fastEmailToken"),
      },
    };
    const response = await fetch(
      serverAddress + "/getSettings",
      requestOptions
    );
    const responseData = await response.json();

    responseData.settingsOpenMenu = JSON.parse(responseData.settingsOpenMenu);
    responseData.settingsQuickSelection = JSON.parse(
      responseData.settingsQuickSelection
    );
    responseData.settingsEnableExtension = JSON.parse(
      responseData.settingsEnableExtension
    );
    responseData.settingsIsAutoInsert = responseData.settingsIsAutoInsert;

    setUserSettings(responseData);
  };

  const getAllTemplates = async () => {
    if (selectedTeam < 0) return;
    try {
      setTemplatesLoaded(false);
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("fastEmailToken"),
        },
      };
      const response = await fetch(
        serverAddress + "/getTemplates/" + selectedTeam,
        requestOptions
      );
      const responseData = await response.json();
      setTemplates(responseData);
    } finally {
      setTemplatesLoaded(true);
    }
  };

  const getAllTeams = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("fastEmailToken"),
      },
    };
    const response = await fetch(serverAddress + "/getTeams", requestOptions);
    const responseData = await response.json();

    if (selectedTeam == -1) {
      for (let i of responseData) {
        if (i.name == "Personal Workspace") {
          setselectedTeam(i.id);
          break;
        }
      }
    }
    setTeams(responseData);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setLoggedIn,
        token,
        setToken,
        serverAddress,
        getAllTemplates,
        getAllTeams,
        templates,
        setTemplates,
        teams,
        setTeams,
        tags,
        userSettings,
        setUserSettings,
        selectedTeam,
        setselectedTeam,
        currentTemplates,
        maxTemplates,
        currentTeams,
        maxTeams,
        isActive,
        subscriptionType,
        timeToDisplay,
        subscription_id,
        getSubscription,
        getUserSettings,
        templatesLoaded,
      }}
    >
      {!pending && props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
