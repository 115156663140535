import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Contexts/AuthContext";
import Swal from "sweetalert2";

import { Editor } from "@tinymce/tinymce-react";
import { TinyImgSrcParser } from "../util/tinyMceSrcParser";

const TemplateForm = () => {
  const navigate = useNavigate();

  const editorRef = useRef(null);

  const context = useContext(AuthContext);
  const [name, setName] = useState("");
  const [shortcut, setShortcut] = useState("");
  const [content, setContent] = useState("");
  const [tags, setTags] = useState("");

  const { token, isLoggedIn, serverAddress, getAllTemplates, selectedTeam } =
    context;
  useEffect(() => {
    if (isLoggedIn === false) {
      //redirect (url guard)
      navigate("/login");
    }
    return () => {};
  }, []);

  const exitTemplateCreation = () => {
    navigate("/menu");
  };

  const addTemplate = async () => {
    try {
      if (name == "" || shortcut == "") {
        Swal.fire({
          title: "Sorry!",
          text: "All fields are mandatory.",
          icon: "error",
          confirmButtonText: "Ok, Thanks!",
        });
        return;
      }

      const parsedHtml = await new TinyImgSrcParser(
        editorRef.current.getContent(),
        token,
        Swal
      ).upload();

      let requestData = {
        name: name,
        shortcut: shortcut,
        content: parsedHtml,
        tags: tags,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(requestData),
      };

      const responseData = await fetch(
        serverAddress + "/addTemplate/" + selectedTeam,
        requestOptions
      );
      if (responseData.status == 401) {
        Swal.fire({
          title: "Sorry!",
          text: "You exceeded maximum number of templates.",
          icon: "error",
          confirmButtonText: "Ok, Thanks!",
        }).then(() => {
          exitTemplateCreation();
          return;
        });
      } else {
        getAllTemplates();
        exitTemplateCreation();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    //    <!---- New Template Popup ----->
    <div id="FastEmail__popup-template" className="FastEmail__popup-background">
      <div className="container p-0">
        <form className="FastEmail__popup col-lg-9 mx-auto" action="#!">
          <div className="FastEmail__popup__header">
            <h1 className="FastEmail__popup__title m-0 flex-grow-1">
              New Template
            </h1>
            <button
              id="FastEmail__popup-template-btn"
              className="Close-btn"
              onClick={exitTemplateCreation}
            >
              <svg
                className="bi bi-x"
                width="24px"
                height="24px"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z"
                  clip-rule="evenodd"
                ></path>
                <path
                  fill-rule="evenodd"
                  d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <hr className="mt-3 mb-0" style={{ color: "#88909b" }} />
          <div className="row d-flex flex-direction-row justify-content-center">
            <div className="col-lg-6 mt-4">
              <p className="FastEmail__popup__p">Template Name</p>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="FastEmail__popup__input"
                type="text"
                placeholder="Ex: Thank You"
                maxlength="80"
              />
            </div>
            <div className="col-lg-6 mt-4">
              <p className="FastEmail__popup__p">Template Shortcut</p>
              <input
                value={shortcut}
                onChange={(e) => setShortcut(e.target.value)}
                className="FastEmail__popup__input"
                type="text"
                placeholder="Ex: Thx"
                maxlength="20"
              />
            </div>
          </div>
          <p className="FastEmail__popup__p mt-4">Template Content</p>
          <p class="FastEmail__popup__p-description">
            <span>Click</span>
            <svg
              className="mx-2"
              width="24"
              height="24"
              focusable="false"
              fill="grey"
            >
              <path
                d="M19 19v-1H5v1h14zM9 16v-4a5 5 0 116 0v4h4a2 2 0 012 2v3H3v-3c0-1.1.9-2 2-2h4zm4 0v-5l.8-.6a3 3 0 10-3.6 0l.8.6v5h2z"
                fill-rule="nonzero"
              ></path>
            </svg>
            <span>icon to insert ready templates.</span>
          </p>

          <Editor
            apiKey="dhju42s3uairpwf11zz2dsco9ftyb0mj8eho1s2twnorp9z1"
            onInit={(evt, editor) => (editorRef.current = editor)}
            init={{
              placeholder: "Ex: Thanks for using SwiftReply!",
              height: 500,
              /*
              skin: "oxide-dark",
              content_css: "dark",
              */
              contextmenu: false,
              verify_html: false,
              entity_encoding: "raw",

              menubar: "custom",
              mobile: {
                menubar: "custom",
              },

              menu: {
                custom: {
                  title: "Insert Veriables",
                  items: "CustomVariable InputVariable DateTime",
                },
              },
              
              templates:
              [

              {
                title: 'New Feature Release',
                description: '',
                content: '<p>Hello {{Show Input: Their Name}},</p> <p>We are happy to announce that our new, updated version is finally live!</p> <p>We took a careful look to make sure all features are working perfectly. Are you ready to get the latest version?<br /><br />All you need to do is to log in and it will update itself.</p> <p>Have a great day,<br />{{Show Input: Your Name}}</p>'
              },
              {
                title: 'New Product Release',
                description: '',
                content: '<p>Hello {{Show Input: Their Name}},</p> <p>We are super excited to announce that {{Show Input: Product Name}} is finally here!</p> <p>Learn more in our website {{Show Input: Website Link}} If you are wondering what makes different.</p> <p>Best Regards,<br />{{Show Input: Your Name}}</p>'
              },
              {
                title: 'Pre-Order',
                description: '',
                content: "<p>Hello {{Show Input: Their Name}},</p> <p><strong>Finally, the big day is here!</strong></p> <p>My product is about to be launched and we want you to be the first to get on board!<br /><br />We'd like to honor all of our dedicated supporters by enabling a pre-order before it is publicly released.<br /><br />Don't miss out on this opportunity, the offer stands for a limited time. Order yours now!</p> <p>Best Regards,<br />{{Show Input: Your Name}}</p>"
              },
              {
                title: 'Product Follow Up',
                description: '',
                content: "<p>Hello {{Show Input: Their Name}},</p> <p>Your feedback is needed!</p> <p>If you're interested, I'd like to hear more about how the product is working out for you.<br />Please let me know some positives and negatives, and if there is any way I can assist you further with the product.</p> <p>I'm available to assist you with whatever is required, and I hope to hear from you soon.</p> <p>Kind regards,<br />{{Show Input: Your Name}}</p>"
              },
              {
                title: 'Product Feedback',
                description: '',
                content: '<p>This is great {{Show Input: Their Name}},<br />Thank you so much for your time and feedback. This helps us a lot in understanding how to improve and bring more value.</p>'
              },
              {
                title: 'Unresponsive Feedback',
                description: '',
                content: "<p>This is great {{Show Input: Their Name}},</p> <p>I was just wondering if you had the time to go through the sample that i’ve sent you?</p> <p>Your feedback is really necessary and I promise it won't take more than 5 mins.</p>"
              },
              {
                title: 'Fantastic Feedback',
                description: '',
                content: "<p>This is fantastic {{Show Input: Their Name}},</p> <p>Your feedback helps us understand our customers’ needs better and aids in bringing more value. We would appreciate your opinion again after we implemented the changes.</p> <p>Thank you for your time!</p>"
              },
              {
                title: 'Query Response',
                description: '',
                content: "<p>This is great {{Show Input: Their Name}},</p> <p>Thanks for reaching out about our services.</p> <p>I'd love to schedule a time to discuss your goals and how may we be able to support you in achieving those goals.</p> <p><strong>When would you be available for a call this week?</strong></p> <p>If you want you can schedule a time that works best for you in my calendar.</p> <p>You can view it here: {{Show Input: Calendar Link}}</p> <p>Thank you,<br />{{Show Input: Your Name}}</p>"
              },
              {
                title: 'After Meeting In-Person',
                description: '',
                content: "<p>Hello {{Show Input: Their Name}},</p> <p>It was great meeting you in person. I'm looking forward to working together.<br /><br />As discussed, below is a list of information I need, and questions I need answering, to proceed:<br />1. [info]<br />2. [info]<br />3. [info]</p> <p>Kind regards,<br />{{Show Input: Your Name}}</p>"
              },
              {
                title: 'Cold Feedback',
                description: '',
                content: "<p>Hello {{Show Input: Their Name}},</p> <p>Did you get the previous email?</p> <p>Your feedback is very important to us!<br />We would love to know what you think about [product/service] so that we can improve it.</p> <p>For you, it will take only 5 minutes but for us, it will be a life changer!</p> <p>Kind regards,<br />{{Show Input: Your Name}}</p>"
              },
              {
                title: 'General Feedback',
                description: '',
                content: "<p>Hello {{Show Input: Their Name}},</p> <p>How are you today?</p> <p>I sent you an email about {{Show Input: Subject}}, which I wanted to talk to you about.</p> <p>When do you think we'll be able to meet for a quick chat? How is {{Show Input: Suggested Time}}?</p> <p>Let me know.</p> <p>Kind regards,<br />{{Show Input: Your Name}}</p>"
              },
           
              ],
              

              setup: function (editor) {
                var toggleState = false;

                /* Variables */

                editor.ui.registry.addMenuItem("CustomVariable", {
                  text: "Options Variable",
                  onAction: function () {
                    editor.insertContent(
                      "<p>{{Option 1, Option 2, Option 3}}</p>"
                    );
                  },
                });

                editor.ui.registry.addMenuItem("InputVariable", {
                  text: "Input Variable",
                  onAction: function () {
                    editor.insertContent(
                      "<p>{{Show Input: Input text placeholder, Option 1, Option 2}}</p>"
                    );
                  },
                });

                editor.ui.registry.addNestedMenuItem("DateTime", {
                  text: "Date & Time",
                  getSubmenuItems: function () {
                    return [
                      {
                        type: "menuitem",
                        text: "Day (Get as a number 1 - 31) | {{getDayAsNumber: Add/Remove Days (Eg 7 / -7) }}",
                        onAction: function () {
                          editor.insertContent("<p>{{getDayAsNumber: 0}}</p>");
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Day of the week (Eg Sunday) | {{getDayOfWeek: Add/Remove Days (Eg 7 / -7) }}",
                        onAction: function () {
                          editor.insertContent("<p>{{getDayOfWeek: 0}}</p>");
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Month (Get as a number 1 - 12) | {{getMonthAsNumber: Add/Remove Days (Eg 7 / -7) }}",
                        onAction: function () {
                          editor.insertContent(
                            "<p>{{getMonthAsNumber: 0}}</p>"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Month (Eg March) | {{getMonth: Add/Remove Days (Eg 7 / -7) }}",
                        onAction: function () {
                          editor.insertContent("<p>{{getMonth: 0}}</p>");
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Year (Eg 2040) | {{getYear: Add/Remove Days (Eg 7 / -7) }}",
                        onAction: function () {
                          editor.insertContent("<p>{{getYear: 0}}</p>");
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Short Year (Eg 40) | {{getShortYear: Add/Remove Days (Eg 7 / -7) }}",
                        onAction: function () {
                          editor.insertContent("<p>{{getShortYear: 0}}</p>");
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Date (Eg 2040-04-11) | {{getISODate: Add/Remove Days (Eg 7 / -7) }}",
                        onAction: function () {
                          editor.insertContent("<p>{{getISODate: 0}}</p>");
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Date (Eg Apr 11 2022) | {{getShortDate: Add/Remove Days (Eg 7 / -7) }} ",
                        onAction: function () {
                          editor.insertContent("<p>{{getShortDate: 0}}</p>");
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Date (Eg Mon Apr 11 2022) | {{getLongDate: Add/Remove Days (Eg 7 / -7) }}",
                        onAction: function () {
                          editor.insertContent("<p>{{getLongDate: 0}}</p>");
                        },
                      },
                    ];
                  },
                });
              },
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

              plugins: [
                "codesample code template advlist autolink lists link image charmap print preview anchor emoticons",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],

              toolbar:
                "codesample code template | undo redo | removeformat | preview | styleselect | fontselect | fontsizeselect | " +
                "bold italic underline strikethrough | link image media table | emoticons | forecolor backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist | outdent indent",

              fontsize_formats: "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",

              toolbar_mode: "sliding",

              media_poster: false,
              image_title: false,
              automatic_uploads: true,
              file_picker_types: "image",

              images_dataimg_filter: function (img) {
                return !img.hasAttribute("internal-blob");
              },

              file_picker_callback: function (cb, value, meta) {
                var input = document.createElement("input");
                input.setAttribute("type", "file");
                input.setAttribute("accept", "image/*");

                input.onchange = function () {
                  var file = this.files[0];
                  var reader = new FileReader();
                  reader.onload = function () {
                    var id = "blobid" + new Date().getTime();
                    var blobCache =
                      window.tinymce.activeEditor.editorUpload.blobCache;
                    var base64 = reader.result.split(",")[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);

                    cb(blobInfo.blobUri(), {
                      title: file.name,
                    });
                  };

                  reader.readAsDataURL(file);
                };

                input.click();
              },
            }}
          />

          <p className="FastEmail__popup__p mt-4">Tags (Optional)</p>
          <p className="FastEmail__popup__p-description">
            Separate tags with commas " , "
          </p>
          <input
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            className="FastEmail__popup__input"
            type="text"
            placeholder="Write tags"
          />
          <a href="#" className="btn btn-secondary mt-4" onClick={addTemplate}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="svgMargin bi bi-save"
              viewBox="0 0 16 16"
            >
              <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
            </svg>
            Save Changes
          </a>
        </form>
      </div>
    </div>
  );
};
export default TemplateForm;